export const APP_CONSTANTS = {
  DEFAULT_LANG: 'de_CH',
  EVENTS_PAGINATION_LIMIT: 20
};

export const EVENTLIST_LAYOUT_TYPES = {
  GRID: 'grid',
  LIST: 'list'
}

export const WIDGET_TYPE_CONSTANTS = {
  APPOINTMENTS: 'appointments',
  EVENTS: 'events',
  COMPACT: 'compact'
};

export const LS_KEYS = {
  PARTNER_OBJECT: 'cbp_pobj_',
};

export const TEMPLATE_CONSTANTS_IDS = {
  WHITELABEL: {
    WHITELABEL_BOOKINGPAGE_PAGETITLE: 102,
    WHITELABEL_BOOKINGPAGE_DESCRIPTION_APPOINTMENT: 103,
    WHITELABEL_BOOKINGPAGE_DESCRIPTION_EVENT: 104
  },
  EVENT_WIDGET_LABELS: {
    WIDGET_EVENT_NO_MORE_FREE_SLOTS_LABEL: 151,
    WIDGET_EVENT_BOOK_LABEL: 154
  },
}

export const CHILD_TO_PARENT_EDIT_EVENTS = {
  DESCRIPTION: 'PARTNER.DESCRIPTION',
  APPOINTMENT_TAB: 'BOOKING_PAGE_HIDE_APPOINTMENT_TAB',
  EVENT_TAB: 'BOOKING_PAGE_HIDE_EVENT_TAB',
  EMPLOYEE_TAB: 'BOOKING_PAGE_HIDE_EMPLOYEE_TAB',
  STORE_TAB: 'BOOKING_PAGE_HIDE_STORE_TAB',
  ABOUT_US_TAB: 'BOOKING_PAGE_HIDE_ABOUT_US_TAB',
  RATINGS_TAB: 'BOOKING_PAGE_HIDE_RATINGS_TAB',
  EDIT_BUSINESS_HOURS: 'PARTNER.BUSINESS_HOURS',
  CALENSO_EDIT_BOOKING_PAGE_FIELDS: 'CALENSO.EDIT_BOOKING_PAGE_FIELDS',
  PARTNER_SETTING: 'PARTNER.SETTINGS',
  PARTNER_BACKGROUND_IMAGE: 'PARTNER.BACKGROUND',
  PARTNER_LOGO: 'PARTNER.LOGO'
};

export const SUBSCRIPTIONS = {
  SUBSCRIPTION_FREE_TYPE: {
    id: 1,
    name: 'free',
    desc: 'Calenso Free',
    maxNumberOfBookings: 100,
  },
  SUBSCRIPTION_FLATRATE_TYPE: {
    id: 5,
    name: 'flatrate',
    desc: 'Calenso Flatrate',
  },
  SUBSCRIPTION_WHITELABEL_TYPE: {
    id: 100,
    name: 'whitelable',
    desc: 'Calenso Whitelable',
  },
  SUBSCRIPTION_ENTERPRISE_TYPE: {
    id: 101,
    name: 'enterprise',
    desc: 'Calenso Emterprise',
  },
  SUBSCRIPTION_STARTER_TYPE: {
    id: 102,
    name: 'starter',
    desc: 'Calenso Starter',
  },
  SUBSCRIPTION_PRO_TYPE: {
    id: 103,
    name: 'pro',
    desc: 'Calenso Pro',
  },
  SUBSCRIPTION_BUSINESS_TYPE: {
    id: 104,
    name: 'business',
    desc: 'Calenso Business',
  },
  SUBSCRIPTION_NEW_ENTERPRISE_TYPE: {
    id: 105,
    name: 'newEnterprise',
    desc: 'Calenso New Enterprise',
  },
};

export const LANGUAGES = [{
  text: 'german',
  value: 'de',
  secondary_text: 'DE',
  locale: 'de_CH',
  flagIcon: '/images/flags/german.png',
}, {
  text: 'french',
  value: 'fr',
  secondary_text: 'FR',
  locale: 'fr_CH',
  flagIcon: '/images/flags/french.png',
}, {
  text: 'italian',
  value: 'it',
  secondary_text: 'IT',
  locale: 'it_CH',
  flagIcon: '/images/flags/italian.png',
}, {
  text: 'english',
  value: 'en',
  secondary_text: 'EN',
  locale: 'en_US',
  flagIcon: '/images/flags/english.png',
}, {
  text: 'dutch',
  value: 'nl',
  secondary_text: 'NL',
  locale: 'nl_NL',
  flagIcon: '/images/flags/dutch.png',
}, {
  text: 'finnish',
  value: 'fi',
  secondary_text: 'FI',
  locale: 'fi_FI',
  flagIcon: '/images/flags/finnish.png',
}, {
  text: 'polish',
  value: 'pl',
  secondary_text: 'PL',
  locale: 'pl_PL',
  flagIcon: '/images/flags/polish.png',
}, {
  text: 'spanish',
  value: 'es',
  secondary_text: 'ES',
  locale: 'es_ES',
  flagIcon: '/images/flags/spanish.png',
}, {
  text: 'swedish',
  value: 'sv',
  secondary_text: 'SE',
  locale: 'sv_SE',
  flagIcon: '/images/flags/swedish.png',
}];

export const SVG_ICONS = [
  'email', 'location', 'location-fill', 'website', 'mobile', 'arrow-down', 'camera', 'pencil', 'grid', 'list', 'check-mark', 'info', 'times', 'more-attendees', 'add-guest', 'expand-less', 'chevron-right', 'spinner'
];

export const CONFERENCE_ERROR_CODES = {
  EXPIRED: 410,
  NOT_BELONGING: 401
};

export const CONFERENCE_WORKERS_LIMIT = {
  MAX_COUNT_TO_SHOW_LESS: 9,
  DEFAULT_LIST_COUNT: 7
};

export const PARTNER_ERROR_CODES = {
  NOT_ACTIVE_SUBSCRIPTION: 403
};
